<template>
  <div>
  <div>
    <div class="has-background-secondary">
      <div class="container content py-6 mb-6">
        <h1 class="has-text-white my-0">Mobile App</h1>
      </div>
    </div>

    <div class="container content pb-6">
      <div style="max-width: 850px">
        <p>
          Seekrite Mobile is simply the safest and most secure way to search the web, period. Double that for a mobile device. Ever wonder why we do something as personal as search in an incredibly insecure device like a browser? Seekrite Mobile looks and feels like a browser, but most of the browser is running on our servers, and all they communicate to your device are instructions on how to paint the screen. 
        </p>

        <p>
          Seekrite is bringing this technology, which originates in the hacker world, to the mainstream. 
        </p>

        <p>
          No website can set anything on your device through Seekrite Mobile and Seekrite Mobile has no access to any of your phone’s data, personal, location, or anything else. iOS. Google.
        </p>
      </div>
    </div>

  </div>
  </div>
</template>

<script>
export default {
  name: "MobileApp"
}
</script>